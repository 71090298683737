import { createElement, useCallback, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { GridItem, Box, Tooltip } from '@chakra-ui/react';
import { AnalyticsContext } from 'context/analyticsContext';
import { getColourForFormat, getIconForFormat } from 'helpers/format';
import { QueuedDownloads } from 'types/queuedDownloads';
import { Title } from 'types/title';
import Label from './Label/component';
import AddDownloadMenu from './AddDownloadMenu/component';

type ItemCardProps = {
  item: Title;
  queuedDownloads: QueuedDownloads;
  setQueuedDownloads: (value: QueuedDownloads) => void;
  renderSmall?: boolean;
};

function ItemCard({
  item,
  queuedDownloads,
  setQueuedDownloads,
  renderSmall,
}: ItemCardProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const sendAnalyticsEvent = useContext(AnalyticsContext);

  const handleOnClick = useCallback(() => {
    sendAnalyticsEvent({
      category: 'Titles',
      action: `Click from ${location.pathname}`,
      label: `${item.title}-${item.code}-${item.id}`,
    });
    navigate(`/titles/${item.id}`);
  }, [
    sendAnalyticsEvent,
    location.pathname,
    item.title,
    item.code,
    item.id,
    navigate,
  ]);

  if (!item) {
    return null;
  }

  const renderLabel = () => {
    if (item.isNew) {
      return (
        <Label
          tooltipLabel={`Created ${format(item.publishedDate, 'MMMM do')}`}
          label="New"
          color="red"
        />
      );
    }
    if (item.isRecentlyUpdated) {
      return (
        <Label
          tooltipLabel={`Updated ${format(item.updatedDate, 'MMMM do')}`}
          label="Updated"
          color="green"
        />
      );
    }
    return null;
  };

  return (
    <GridItem
      data-testid={`title-${item.id}`}
      w="100%"
      maxW="300px"
      height={[40, 40, renderSmall ? 40 : 60]}
      justifyContent="flex-end"
      position="relative"
    >
      <Box
        role="button"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        tabIndex={0}
        onClick={handleOnClick}
        onKeyDown={(e) => e.key === 'Enter' && handleOnClick()}
        cursor="pointer"
        w="100%"
        h="100%"
        display="inline-flex"
        flexDir="column"
        alignItems="stretch"
      >
        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          p={1}
          backgroundImage={item.imageUrl}
          backgroundSize="cover"
          backgroundPosition="center center"
          flex={1}
        >
          {renderLabel()}
          <Tooltip hasArrow label={item.format} placement="left" fontSize="md">
            <Box
              aria-label={item.format}
              role="img"
              display="inline-block"
              boxShadow="md"
              p={1}
              m={0.5}
              borderRadius={100}
              backgroundColor={`${getColourForFormat(item.format)}.200`}
            >
              {createElement(getIconForFormat(item.format), {
                boxSize: 7,
                color: `${getColourForFormat(item.format)}.700`,
              })}
            </Box>
          </Tooltip>
        </Box>
        <Box p="4">
          <Box
            mt="1"
            fontSize="lg"
            lineHeight="shorter"
            height={['25px', '25px', renderSmall ? '25px' : '45px']}
            overflow="hidden"
          >
            {item.title}
          </Box>
          <Box mt={2} pr={14} height="40px" display="flex" alignItems="center">
            <Box
              mt={2}
              color="gray.500"
              fontWeight="regular"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
            >
              {item.topic}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box position="absolute" bottom={4} right={4}>
        <AddDownloadMenu
          assets={item.assets}
          availableAs={item.availableAs}
          titleCode={item.code}
          queuedDownloads={queuedDownloads}
          setQueuedDownloads={setQueuedDownloads}
        />
      </Box>
    </GridItem>
  );
}

export default ItemCard;
