import { Box, Tag, TagLabel, Tooltip } from '@chakra-ui/react';

type LabelProps = {
  label: string;
  tooltipLabel: string;
  color: 'green' | 'red';
};

function Label({ tooltipLabel, label, color }: LabelProps) {
  return (
    <Tooltip hasArrow label={tooltipLabel} placement="left" fontSize="md">
      <Box display="inline-block" p={1} m={0.5}>
        <Tag
          size="md"
          borderRadius="full"
          variant="solid"
          backgroundColor={color}
        >
          <TagLabel>{label}</TagLabel>
        </Tag>
      </Box>
    </Tooltip>
  );
}

export default Label;
