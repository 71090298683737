import { useEffect, useState, ReactNode } from 'react';
import { useLocation } from 'react-router';
import { UserContext, UserProps } from 'context/userContext';
import { amplifyConfig } from 'config/amplify-config';

type LoginWrapperProps = {
  children: ReactNode;
};

const Auth = amplifyConfig();

function LoginWrapper({ children }: LoginWrapperProps) {
  const location = useLocation();
  const [user, setUser] = useState<UserProps>({});
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    Auth.currentSession()
      // We have got a authenticated user session
      .then((suc) => {
        const { payload } = suc.getIdToken();
        const accountId = payload.email.split('@')[1];
        setUser({
          userId: payload.sub,
          userProducts: payload['cognito:groups'],
        });
        pendo.initialize({
          visitor: {
            id: payload.sub,
            products: payload['cognito:groups'],
          },
          account: {
            id: accountId,
            name: accountId,
          },
        });
      })
      .catch(() => setUser({}))
      .finally(() => setIsInitialized(true));
  }, [location]);

  if (!isInitialized) {
    return null;
  }
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export default LoginWrapper;
