import { isEqual, differenceInDays } from 'date-fns';
import { CurationType } from 'types/curationTypes';
import {
  CuratedItem,
  CuratedItemRes,
  CuratedSectionItem,
} from 'types/curatedItem';
import { Title, TitleRes } from 'types/title';

type SortAssetLabelsProps = Pick<Title, 'assetPreviewId' | 'assets'>;

function sortAssetLabels({ assets, assetPreviewId }: SortAssetLabelsProps) {
  const availableAs = assets.map((asset) => asset.assetLabel).sort();
  const previewAsset = assets.find((asset) => asset.id === assetPreviewId);
  const previewAssetLabel = previewAsset?.assetLabel;

  if (previewAssetLabel) {
    const labelIndex = availableAs.indexOf(previewAssetLabel);
    if (labelIndex > -1) {
      availableAs.splice(labelIndex, 1);
      availableAs.unshift(previewAssetLabel);
    }
  }
  return availableAs;
}

export function mapCatalogueToolTitle(item: TitleRes): Title {
  const publishedDate = new Date(item.dateFirstPublished);
  const updatedDate = item.dateLastPublished
    ? new Date(item.dateLastPublished)
    : publishedDate;

  const isRepublished = !isEqual(publishedDate, updatedDate);

  const availableAs = sortAssetLabels(item);

  return {
    imageUrl: item.thumbnail ? item.thumbnail : '',
    imageAlt: item.titleName,
    format: item.format,
    topic: item.topic ? item.topic.topicName : '',
    title: item.titleName,
    summary: item.description,
    durationMinutes: item.duration,
    isNew: differenceInDays(new Date(), publishedDate) < 28,
    isRecentlyUpdated:
      isRepublished && differenceInDays(new Date(), updatedDate) < 7,
    age: updatedDate,
    updatedDate,
    publishedDate,
    id: item.id,
    assetPreviewId: item.assetPreviewId,
    availableAs,
    code: item.titleCode,
    assets: item.assets,
  };
}

export function mapCuration(
  type: CurationType,
  curation: CuratedItemRes,
): CuratedItem {
  const sections: CuratedSectionItem[] = [];
  curation.curatedSections?.map((section) => {
    const sectionTitles = section.titles.map((title) =>
      mapCatalogueToolTitle(title),
    );
    sections.push({ ...section, titles: sectionTitles });
    return section;
  });
  return {
    id: curation.id,
    name: curation.name,
    type,
    summary: curation.summary,
    isCampaign: type === CurationType.Campaign,
    banner: curation.thumbnail,
    imageUrl: curation.thumbnail,
    startDate: curation.startDate ? new Date(curation.startDate) : 0,
    endDate: curation.endDate ? new Date(curation.endDate) : 0,
    lastUpdated: new Date(curation.updatedAt),
    sections,
    numItems: curation.totalNumberOfTitles,
  };
}

export function mapManyCurations(
  type: CurationType,
  curation: CuratedItemRes,
): CuratedItem {
  return {
    id: curation.id,
    name: curation.name,
    type,
    summary: curation.summary,
    isCampaign: type === CurationType.Campaign,
    banner: curation.thumbnail,
    imageUrl: curation.thumbnail,
    startDate: curation.startDate ? new Date(curation.startDate) : 0,
    endDate: curation.endDate ? new Date(curation.endDate) : 0,
    lastUpdated: new Date(curation.updatedAt),
    numItems: curation.totalNumberOfTitles,
  };
}
